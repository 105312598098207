<template>
  <div class="view-feedback">
    <base-content-wrapper :compensateNavTop="true">
      
      <base-loader v-if="loading"></base-loader>

      <template v-if="(!error && error !== null) && customer">
        <base-title mB="l">{{ this.textContent.greeting1 }}, {{ this.customer.title }}!</base-title>
        <base-title size="s" tag="h5" :mB="40">{{ this.textContent.greeting2 }}</base-title>

        <feedback-scoring
          v-for="(question, index) in textContent.questions"
          :key="index"
          :amount="5"
          :index="index"
          :question="question"
          @answered="answered"
          :mB="30"
        ></feedback-scoring>

        <base-textarea
          v-model="freeComment"
          :label="textContent.label"
          :placeholder="textContent.placeholder"
          mB="l"
        ></base-textarea>

        <base-button
          @click="submit"
          :disabled="!submitAllowed"
          mB="l"
        >{{ this.textContent.submit }}</base-button>
        <base-text v-if="error">{{ this.textContent.errors.submit }}</base-text>
      </template>

      <template v-if="error">
        <base-title mB="l">{{ this.textContent.errors.noId }}</base-title>

        <!-- Return to constlet.com -->
        <base-link mode="router" to="/">
          {{ this.textContent.errors.link }}
          <span>&#8594;</span>
        </base-link>
      </template>

    </base-content-wrapper>
  </div>
</template>

<script>
//ONGELMAT:
//-KIELI-AGNOSTINEN ROUTING PUUTTUU (routes.js) !!!!!!
//-TEE TARKASTELU ONKO KYSEISEN ASIAKKAAN ID:LLÄ JO ANNETTU PALAUTE VAI EI, JA JOS ON -> ERROR
//-OLISIKO PAREMPI TEHDÄ PARAMSILLA VAI QUERYLLÄ VAI ONKO VÄLIÄ? (MITÄ TARKOITUSTA QUERY PALVELEE?)

import feedbackScoring from '@/components/feedbackScoring'

export default {
  name: 'viewFeedback',

  components: { feedbackScoring },

  data() {
    return {
      loading: true,
      error: false,
      customer: null,
      answers: {},
      freeComment: ''
    }
  },

  async created() {
    let customerId = this.$route.query.id
      ? this.$route.query.id
      : false
    let customerData = await this.$api.getData('projects', { document: customerId })

    if (customerId && customerData) {
      this.customer = customerData
      this.error = false
      this.loading = false
    } else {
      this.error = true
      this.loading = false
    }

    if (this.textContent) {
      this.textContent.questions.forEach((question, index) => {
        this.$set(this.answers, index, null)
      })
    }
  },

  computed: {
    textContent() {
      let inEnglish = this.$store.state.app.constlet.inEnglish
      let locale = inEnglish ? 'en' : 'fi'
      return this.$store.state.content.text[locale].feedback
    },

    submitAllowed() {
      let allowed = Object.values(this.answers).every(answer => answer !== null)
      return allowed
    }
  },

  methods: {
    answered(answer) {
      console.log(answer);
      
      this.answers[answer.question] = answer.answer
    },

    async submit() {
      let feedback = Object.assign({}, this.answers, { freeComment: this.freeComment })
      this.loading = true
      
      await this.$api.updateData('projects', this.$route.query.id, { feedback: feedback })
      .catch(err => {
        this.error = true
      })

      this.error = false
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
$view-feedback--color: $app-color--theme;
$view-feedback--color-bg: $app-color--main;
$temporary-hl-color: rgb(113, 209, 241); // MUUTA!

.view-feedback {
  min-height: 100vh;
  background: $view-feedback--color-bg;
  color: $view-feedback--color !important;

  &::v-deep * {
    color: $view-feedback--color;
    label { color: $temporary-hl-color; } // MUUTA!

    .base-link { color: $temporary-hl-color; } // MUUTA!
  }
}
</style>