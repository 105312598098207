<template>
  <div class="feedback-scoring" :style="mixinMargins">
    <base-title size="s" tag="h5" mB="l">
      {{ this.index + 1 }}: {{ this.question }}
    </base-title>
    <div class="scores">
      <base-checkbox
        v-for="(option, index) in options"
        :key="index"
        :state="option.state"
        @click="check(index)"
      ></base-checkbox>
    </div>
  </div>
</template>

<script>
import { margins } from '@/utils/mixins'

export default {
  name: 'feedbackScoring',

  mixins: [margins],

  props: {
    value: Boolean,
    amount: {
      type: Number,
      default: 10
    },
    index: {
      type: Number,
      default: 0
    },
    question: {
      type: String,
      default: 'How...'
    }
  },

  data() {
    return {
      options: [],
      joo: false
    }
  },

  created() {
    for (let i = 0; i < this.amount; i++) {
      this.options.push({ state: false })
    }
  },

  methods: {
    check(index) {
      if (!this.options[index].state) {
        this.options.forEach((option, i) => {
          if (i === index) {
            option.state = true
          } else { // make sure no other option is checked simultaneously
            option.state = false
          }
        })

        this.$emit('answered', { question: this.index, answer: index })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.feedback-scoring {

  .scores {
    display: flex;
    justify-content: space-between;
    max-width: 500px;
    margin: 0 auto;
  }
}
</style>
